import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import photos from '../data/photos';

export default function Photos() {
  useEffect(() => {
    $('.photoThumbnailLink').on('click', function (e) {
      $('#myModal').modal('show');
      $('#slideshowCarousel').carousel('pause');
      $('#slideshowCarousel').carousel(Number($(this).attr('href').replace('#', '')));
      $('#slideshowCarousel').carousel('pause');
      e.preventDefault();
    });

    $('.carousel-inner .item').first().addClass('active');
    $('#slideshowCarousel').carousel();
  });

  return (
    <Layout page="photos">
      <SEO title="Photos" />
      <p>&nbsp;</p>
      <div className="photoTile">
        {photos.map((photo, i) => (
          <div key={photo.src} className="photoThumbnail">
            <a href={`#${i}`} className="photoThumbnailLink">
              <img src={`/img/photos/165px/${photo.src}`} alt="" />
            </a>
          </div>
        ))}
      </div>
      <div className="clearBoth">&nbsp;</div>
      {/* <!-- Modal --> */}
      <div
        aria-hidden="true"
        aria-labelledby="myModalLabel"
        className="modal hide fade"
        id="myModal"
        role="dialog"
        tabIndex={-1}
      >
        <div className="modal-header">
          <button aria-hidden="true" className="close" data-dismiss="modal" type="button">
            ×
          </button>
          <h4 id="myModalLabel">OUR MEMORIES</h4>
        </div>
        <div className="modal-body">
          <div className="carousel slide" id="slideshowCarousel">
            {/* <!-- Carousel items --> */}
            <div className="carousel-inner">
              {photos.map((photo) => (
                <div key={photo.src} className="item">
                  <div className="slideshowImageWrapper">
                    <img src={`/img/photos/500px/${photo.src}`} alt="" />
                  </div>
                </div>
              ))}
            </div>
            {/* <!-- Carousel nav --> */}
            <a className="carousel-control left" data-slide="prev" href="#slideshowCarousel">
              &lsaquo;
            </a>
            <a className="carousel-control right" data-slide="next" href="#slideshowCarousel">
              &rsaquo;
            </a>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" aria-hidden="true" className="btn" data-dismiss="modal">
            Exit slideshow
          </button>
        </div>
      </div>
    </Layout>
  );
}
